<template>
    <div>
        <table class="table table-hover">
            <thead>
                <tr>
                    <!-- <th scope="col">Viewpoint ID</th> -->
                    <th scope="col">Name</th>
                    <th scope="col">Tags</th>
                    <th scope="col">Duration</th>
                    <th scope="col">Winner</th>
                    <th scope="col">Events (K/C/N)</th>
                    <th scope="col">Actions</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="viewpoint in viewpoints" :key="viewpoint.id"  @click="gotoViewpoint(viewpoint.id)" style="cursor:pointer;">
                    <!-- <th scope="row">{{viewpoint.id}}</th> -->
                    <th scope="row">{{viewpoint.name || viewpoint.id || "N/A" }}</th>
                    <td>
                        <!-- <b-badge v-for="tag in viewpoint.tags || []" :key="tag" variant="primary">{{tag}}</b-badge> -->
                        <div v-for="tag in viewpoint.tags" :key="tag" class="badge bg-secondary my-auto mx-1" style="height: 20px;">{{tag}}</div>
                    </td>
                    <td>{{viewpoint.duration ? humanizeDuration(viewpoint.duration * 1000, { round: true }) : "N/A" }}</td>
                    <td>{{viewpoint.myTkdData && viewpoint.myTkdData.winnerName  ? viewpoint.myTkdData.winnerName : "N/A" }}</td>
                    <td>{{viewpoint.kicks ? viewpoint.kicks : "N/A" }}</td>
                    <td>
                        <div @click.stop="deleteViewpoint(viewpoint.id)" class="btn btn-danger btn-xs" size="sm" variant="danger">Delete</div>
                    </td>
                </tr>
            </tbody>
        </table>

        <div v-if="viewpoints.length == 0"  style="margin-top: -15px; margin-left: 0px; margin-bottom: 10px;">No viewpoints</div>
    </div>
</template>

<script>
import humanizeDuration from "humanize-duration"

import { mapGetters } from 'vuex';
export default {
    data() {
        return {
            deletedViewpointIds: [],
        };
    },
    mounted() {
    },
    props: {
        'data': {
            type: Object,
        }
    },
    computed: {
        ...mapGetters(['selectedAthlete']),
        viewpoints() {
            return Object.values(this.data).filter(x => this.deletedViewpointIds.indexOf(x.id) == -1);
        }
    },
    methods: {
        humanizeDuration,
        gotoViewpoint(viewpointId) {
            this.$router.push({ 
                name: "Viewpoint",
                params: {
                    id: viewpointId
                }
            })
        },
        async deleteViewpoint(viewpointId) {
            let { dismiss } = await this.Swal.fire({
                title: "Are you sure?",
                text: "This will permanently delete the resourec",
                showCancelButton: true,
                showConfirmButton: true,
            });
            if (dismiss) return;

            // Delete
            this.Swal.showLoading();
            await this.$store.dispatch("deleteViewpoint", viewpointId);
            this.deletedViewpointIds.push(viewpointId)
            
            this.Swal.close()
        }
    }
}
</script>

<style scoped>

.table > :not(caption) > * > * { 
    padding: 0;
}

</style>