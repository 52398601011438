<template>
  <!-- Viewpoints -->
  <div class="">
    <div class="d-flex justify-content-between">
      <div class="h4">Matches</div>
      <div>
        <button
          class="btn btn-success"
          data-bs-toggle="modal"
          data-bs-target="#createViewpointModal"
        >
          Add new Viewpoint
        </button>
      </div>
    </div>

    <!-- Viewpoints table -->
    <div style="margin-top: -5px">
      <div v-if="fetchingViewpoints">
        <img class="loader-lg" src="@/assets/loader.gif" />
      </div>
      <div v-else-if="errorFetchingViewpoints">
        Error loading viewpoints: {{ errorFetchingViewpoints }}
      </div>
      <ViewpointsTable v-else :data="viewpoints"></ViewpointsTable>
    </div>

    <!-- Viewpoint create modal -->
    <div
      class="modal fade"
      ref="createViewpointModal"
      tabindex="-1"
      id="createViewpointModal"
      aria-labelledby="createViewpointModalLabel"
      aria-hidden="true"
      data-backdrop="false"
    >
      <div
        class="
          modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable
        "
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Create a new Viewpoint</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col">
                <label for="inputEmail4">Athlete:</label>
                <input
                  type="text"
                  class="form-control"
                  disabled
                  v-model="selectedAthlete.id"
                />
              </div>

              <div class="col">
                <label for="inputEmail4">Match:</label>
                <select class="form-control" v-model="viewpointData.matchId">
                  <option disabled value="">Please select one</option>
                  <option
                    v-for="match in Object.values(selectedAthleteMatches)"
                    :key="match.id"
                    :value="match.id"
                  >
                    {{ match.id }}
                  </option>
                </select>
              </div>
            </div>

            <div class="row mt-3">
              <div class="form-group col">
                <label for="inputEmail4">Public Video URL:</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="viewpointData.url"
                />
              </div>
              <div class="form-group col">
                <label for="inputEmail4"><b>OR</b> Select a file</label>
                <input
                  type="file"
                  class="form-control"
                  name="Video"
                  ref="viewpointDataFile"
                  :disabled="isUploadingVideo"
                  @change="
                    viewpointFilesChange(
                      $event.target.name,
                      $event.target.files
                    )
                  "
                  accept="video/*"
                />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              class="btn btn-success"
              @click="submitViewpoint"
              :disabled="creatingViewpoint"
            >
              Create Viewpoint<img
                v-if="creatingViewpoint"
                class="loader"
                src="@/assets/loader.gif"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ViewpointsTable from "@/components/ViewpointsTable";
import { createViewpoint, getViewpoints } from "@/api";
import { mapGetters } from "vuex";
export default {
  components: {
    ViewpointsTable,
  },
  mounted() {
    console.log(`Mounted, loading viewpoints`);
    this.fetchViewpoints();
  },
  data() {
    return {
      isUploadingVideo: false,
      viewpoints: {},
      fetchingViewpoints: false,
      errorFetchingViewpoints: false,
      creatingViewpoint: false,
      viewpointData: {
        matchId: "",
        url: "",
        file: null,
      },
    };
  },
  computed: {
    ...mapGetters(["selectedAthlete", "selectedAthleteMatches"]),
  },
  // watch: {
  //   selectedAthlete() {
  //     this.fetchViewpoints();
  //   }
  // },
  methods: {
    ...mapGetters(["selectedMatchesByAthlete"]),
    viewpointFilesChange(fileType, files) {
      console.log("Got files change!");
      if (fileType != "Video")
        return this.Swal.fire("error", "This filetype is unsupported", "error");
      let file = files[0];
      this.viewpointData.file = file; // of type "File".
    },

    async fetchViewpoints() {
      this.fetchingViewpoints = true;
      try {
        let viewpointsResponse = await getViewpoints({
          athleteId: this.selectedAthlete.id,
        });
        console.log("Got viewpoints");
        for (let viewpointId of Object.keys(viewpointsResponse.data.viewpoints)) {
          this.viewpoints[viewpointId] = viewpointsResponse.data.viewpoints[viewpointId];
          this.$store.commit("UPDATE_VIEWPOINT", this.viewpoints[viewpointId]);  
        }
      } catch (e) {
        this.errorFetchingViewpoints = e.message;
      } finally {
        this.fetchingViewpoints = false;
      }
    },

    async submitViewpoint() {
      this.creatingViewpoint = true;
      try {
        if (this.viewpointData.url && this.viewpointData.file) {
          throw new Error("You must choose EITHER a url or file");
        }
        if (!this.viewpointData.url && !this.viewpointData.file) {
          throw new Error("You must choose a url or file");
        }

        if (this.viewpointData.file) {
          // Upload the file to Azure Blob Storage and retrieve a public URL to it to save in the viewpoint.

          const { sasUrl } = (
            await api.post("/Viewpoints/upload", {
              fileName: this.viewpointData.file.name,
              mimeType: this.viewpointData.file.type,
            })
          ).data;

          this.Swal.fire(
            "Hang on",
            "Just uploading... This could take a while, we should probably add a progress bar here...",
            "info"
          );

          // const blobClient = new BlobClient(sasUrl);
          const blockBlobClient = new BlockBlobClient(sasUrl);
          await blockBlobClient.uploadData(this.viewpointData.file, {
            blobHTTPHeaders: {
              blobContentType: this.viewpointData.mimeType,
            },
          });

          delete this.viewpointData.file;
          this.viewpointData.url = sasUrl;

          this.Swal.close();
        }

        await createViewpoint({
          ...this.viewpointData,
          athletes: {
            [this.selectedAthlete.id]: true,
          },
        });

        await this.fetchViewpoints();
        console.log("Tried to hide");
        // Redirect to the viewpoint?
      } catch (e) {
        this.Swal.fire({ title: "Uh-o", text: e.message, icon: "error" });
      } finally {
        this.creatingViewpoint = false;
      }
    },
  },
};
</script>

<style>
</style>